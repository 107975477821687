import React, { useState } from "react";
import "./Training.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import joinUsImage from "../assets/austin-kehmeier-lyiKExA4zQA-unsplash.jpg";

const StudentForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    description: "",
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError("");
  };

  const validateForm = () => {
    if (!formData.name || !formData.email || !formData.mobile) {
      setError("All fields are required");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fill in all required fields");
      return;
    }

    try {
      const response = await axios.post(
        "https://thinkbig-api.onrender.com/api/students",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log("Student data saved successfully");
        toast.success("Submitted successfully");
        setFormData({ name: "", email: "", mobile: "", description: "" });
      } else {
        console.error("Error saving student data");
        setError("Error saving student data");
        toast.error("Error saving student data");
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError("Student with the same email already exists");
        toast.error("Student with the same email already exists");
      } else {
        console.error("Error saving student data:", error);
        setError("Error saving student data. Please try again.");
        toast.error("Error submitting. Please try again.");
      }
    }
  };

  return (
    <div className="form-container">
      {/* <div>
        <img className="joinUsImage" src={joinUsImage} alt="joinUsImage" />
      </div> */}

      <div>
        <form onSubmit={handleSubmit} className="form">
          <h1 className="join-us-text">Join Us !!!</h1>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Name"
          />

          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
          />

          <input
            type="tel"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            placeholder="Mobile"
          />

          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Description"
          />

          <button className="submit-button" type="submit">
            SUBMIT
          </button>

          <ToastContainer />
        </form>
      </div>
    </div>
  );
};

export default StudentForm;
