import React from "react";
import "./ContactUs.css";

const ContactUs = () => {
  return (
    <div className="contact-us-container">
      <div className="map-contact-container">
      <div className="contact-container">
          <ul>
         
          
            <li>
              <h4> ADDRESS</h4>
              Plot No.28,5th Floor, Olympia Teknos Park, South Phase,SIDCO Industrial Estate,
              Guindy, Chennai, Tamil Nadu 600032, India{" "}
            </li>{" "}
            <li>
              {" "}
              <h4>OUR WEBSITE</h4>www.thinkbigsoft.com{" "}
            </li>{" "}
            <li>
              <h4>MOBILE</h4>+91 75400 73500
            </li>{" "}
            <li>
              <h4>TIMING</h4>
              Mon - Fri: 9:00 - 6:00, Sat: 9:00 - 6:00 (alternative days) ,Closed on Sundays and
              public holidays
            </li>{" "}
            <li>
              <h4>LANDMARK</h4>
              Ekkattuthangal Station (3 mins walk away) Alandur Station (5 mins
              walk away) Bus Station: Ekkattuthangal
            </li>
          </ul>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1943.6711152192033!2d80.19981823876311!3d13.013865110146268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267d6ecb6b38b%3A0xa2c51b24c0f6bab5!2sThe%20Executive%20Centre%20-%20Olympia%20Teknos!5e0!3m2!1sen!2sin!4v1703397365191!5m2!1sen!2sin"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          title="map"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        {/* Add your home content here */}
       
      </div>
    </div>
  );
};

export default ContactUs;
